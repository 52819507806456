import { Content, Page } from 'journey-ui'
import Head from 'next/head'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import { AppFooter } from './AppFooter'
import { AppHeader } from './AppHeader'
import { useCssVars } from '../hooks/useCssVars'

interface Props {
  title: string
  description: string
  className?: string
  backUrl?: string
  fullScreen?: boolean /* This removes the padding from content. */
  brand: Brand
}

export const AppPage: React.FC<Props> = ({ title, description, fullScreen, backUrl, children, className, brand }) => {
  const cssVarStyleContent = useCssVars(brand)

  useEffect(() => {
    if (brand.code_head === '') {
      return
    }
    const script = document.createElement('script')

    script.innerHTML = brand.code_head
    script.className = 'code-head'

    document.head.append(script)

    return () => {
      script.remove()
    }
  }, [brand.code_head])

  return (
    <Page className={className}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" href="/favicon.ico" />
        <link rel="preload" href="/fonts/Lato/Lato-Regular.ttf" as="font" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/BalooBhai2/BalooBhai2-Regular.woff2" as="font" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/BalooBhai2/BalooBhai2-Bold.woff2" as="font" crossOrigin="anonymous" />
        {brand.css_vars?.header_font ? (
          <>
            <link rel="preload" href={`https://fonts.googleapis.com/css?family=${brand.css_vars.header_font}`} as="style" />
            <link rel="stylesheet" href={`https://fonts.googleapis.com/css?family=${brand.css_vars.header_font}`} />
          </>
        ) : null}
        {brand.css_style !== '' ? <style>{brand.css_style}</style> : null}
        <style>
          {cssVarStyleContent}
        </style>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=1, user-scalable=0" />
      </Head>
      <AppHeader brand={brand} backUrl={backUrl} />
      <Content className={classNames('justify-start', { 'p-0': !!fullScreen })}>
        {children}
      </Content>
      <AppFooter />
    </Page>
  )
}
